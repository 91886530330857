import React, { Component, Fragment } from 'react';

import { Switch, Redirect, NavLink } from "react-router-dom";
import { Menu, Container, Message } from 'semantic-ui-react';
import { SemanticToastContainer } from 'react-semantic-toasts';

import AppHeader from '../../Components/AppHeader';
import Configuration from "./configuration/Configuration";
import Licenses from "./licenses/LicensesView.jsx";
import PhoneConfigurationView from './phones/PhoneConfigurationView'

import PhoneTransferListView from './transfer/PhoneTransferListView'
import PhoneList from './phones/PhonesList';
import OnlineUadAlert from './alerts/OnlineUadAlert';
import Locations from '../Locations';
import Dashboard from './dashboard/Dashboard';

import * as UserActions from '../../actions/users';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { makeCancelable } from '../../utils';

const mapping = {
    'dashboard':        { icon: "area graph", header: "Dashboard" },
    'phones':           { icon: "phone",      header: "Téléphones" },
    'configuration':    { icon: "settings",   header: "Configuration" },
    'licenses':         { icon: "credit card outline",    header: "Licences Bicom" },
    'users':            { icon: "users",      header: "Utilisateurs" },
    'phone':            { icon: "phone",      header: "Téléphones" },
    'alerts':           { icon: "bell",       header: "Supervision" },
    'transfers':        { icon: "paper plane",       header: "Transferts"},
};

class AdminView extends Component {
    state = { activeItem: 'phones'  }

    constructor(props) {
        super(props);
        this.get_owner = makeCancelable(this.props.users.get_owner(this.props.client_id));
        this.get_owner.promise.then(response => {
            if (response && response.owner_id) {
                this.setState({owner_id:response.owner_id});
            }
        });
    };

    componentWillUnmount() {
        if (this.get_owner)
            this.get_owner.cancel();
           
    };

    handleItemClick = (e, { name }) => {
        e.stopPropagation();
        this.setState({ activeItem: name } )
    }

    handleIsActive = (params, activeItem) => event=> {
        var re = new RegExp(activeItem+ '$', 'g');

        let test= window.location.pathname.match(re);
        if (test && this.state !== activeItem) {
             setTimeout(() => {
                 this.setState((state) => activeItem === state.activeItem ? (null) : ({activeItem}));
             }, 0);
             return true;
        }
        return false;
    }

    render() {
        const meta=mapping[this.state.activeItem];
        const { client_id, server_id } = this.props;
        const { phone_id, phone_name } = this.props.phone;   
        const { role } = this.props.user.user;       

        return (
            <Fragment>
                <SemanticToastContainer position="top-right" />
                <Container><AppHeader icon={meta.icon} header={meta.header} user={this.props.user} owner_id={this.state.owner_id}></AppHeader></Container>
                <div className="detailview-content container-fluid">
                    <Menu

                     pointing secondary stackable>
                        <Menu.Item as={NavLink} content='Dashboard' to={Locations.ClientDashboardView.toUrl({ client_id, server_id })} isActive={this.handleIsActive(Locations.ClientDashboardView, 'dashboard')} name='dashboard' onClick={this.handleItemClick} />
                        <Menu.Item as={NavLink} content='Téléphones' to={Locations.ClientPhoneListView.toUrl({ client_id, server_id })} isActive={this.handleIsActive(Locations.ClientPhoneListView, 'phones')} name='phones'  onClick={this.handleItemClick} />
                        {/* <Menu.Item as={NavLink} content='Utilisateurs' to={Locations.ClientUsersView.toUrl({ client_id, server_id })} isActive={this.handleIsActive(Locations.ClientUsersView, 'users')} name='users'  onClick={this.handleItemClick} /> */}
                        <Menu.Item as={NavLink} content='Supervision' to={Locations.ClientAlertView.toUrl({ client_id, server_id })} isActive={this.handleIsActive(Locations.ClientAlertView, 'alerts')} name='alerts'  onClick={this.handleItemClick} />
                        <Menu.Item as={NavLink} content='Licences' to={Locations.ClientLicenseView.toUrl({ client_id, server_id })} isActive={this.handleIsActive(Locations.ClientLicenseView, 'licenses')} name='licenses' onClick={this.handleItemClick} />
                        <Menu.Item as={NavLink} content='Configuration' to={Locations.ClientConfigurationView.toUrl({ client_id, server_id })} isActive={this.handleIsActive(Locations.ClientConfigurationView, 'configuration')} name='configuration' onClick={this.handleItemClick} />
                        {role === "admin" ?
                            <Menu.Item as={NavLink}  content='Transferts' to={Locations.ClientTransferListView.toUrl({ client_id, server_id })} isActive={this.handleIsActive(Locations.ClientTransferListView, 'transfers')} name='transfers' onClick={this.handleItemClick} />
                        : null }                        
                        {phone_id > 0 && <Menu.Item as={NavLink} content={`${phone_name}`} to={Locations.ClientPhoneView.toUrl({ client_id, server_id, phone_id: phone_id })} isActive={this.handleIsActive(Locations.ClientPhoneView, 'phone')} name='phone' onClick={this.handleItemClick} />}                        
                    </Menu>
                        {/* todo: créer les vues */}
                        <Switch>
                            {Locations.ClientDashboardView.toRoute({ component: Dashboard, invalid: NoMatch }, true)}
                            {Locations.ClientConfigurationView.toRoute({ component: Configuration, invalid: NoMatch }, true)}
                            {Locations.ClientLicenseView.toRoute({component: Licenses, invalid: NoMatch }, true)}
                            {Locations.ClientUsersView.toRoute({ component: Users, invalid: NoMatch }, true)}
                            {Locations.ClientAlertView.toRoute({ component: OnlineUadAlert, invalid: NoMatch }, true)}
                            {Locations.ClientPhoneListView.toRoute({ component: PhoneList, invalid: NoMatch }, true)}
                            {Locations.ClientPhoneView.toRoute({ component: PhoneConfigurationView, invalid: NoMatch }, true)}
                            {Locations.ClientTransferListView.toRoute({ component: PhoneTransferListView, invalid: NoMatch }, true)}
                            <Redirect to="/404" />
                        </Switch>

                </div>
            </Fragment>
        );
    }
}

const Users = ({ server_id, client_id }) => (
    <div style={{marginTop:15,marginLeft:15}}>
        <Message compact>Il n'y a aucun utilisateur MaConfig assigné à cet environnement.</Message>
    </div>
);


const NoMatch = ({ location }) => (
    <Redirect to="/404" />
);


const mapStateToProps = state => ({
    phone : state.phone_list,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    users: bindActionCreators(UserActions, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdminView);
