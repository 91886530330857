import { axiosApi as axios, apiUrl } from "../constants/index";
import { handle_error } from './session'

export const get_DID_Cli_Routing = (server_id, did_id) => {
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem('x-token');
        let config = { params: { server_id, did_id }, headers: { 'Authorization': "Bearer " + sessionId } };
        if (server_id && did_id) {
            return new Promise((resolve, reject) => {
                axios.get(`${apiUrl}/api/1.0/bicom/did/clirouting`, config)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        handle_error(dispatch, reject, error);
                    });
            });
        }
    };
}

export const get_client_db_data = (login, password, baseName, tableName, baseAddress, basePort, server_id, hostSSH, portSSH, usernameSSH, sshFile, sshPasswordConnection, passwordSSH) => { //from DB client
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem('x-token');

        const formData = new URLSearchParams();
        formData.append('login', login);
        formData.append('password', password);
        formData.append('baseName', baseName);
        formData.append('tableName', tableName);
        formData.append('baseAddress', baseAddress);
        formData.append('basePort', basePort);
        formData.append('server_id', server_id);
        formData.append('hostSSH', hostSSH);
        formData.append('portSSH', portSSH);
        formData.append('usernameSSH', usernameSSH);
        formData.append('sshFile', sshFile);
        formData.append('sshPasswordConnection', sshPasswordConnection);
        formData.append('passwordSSH', passwordSSH);

                    
        const response = axios.request({
            url: `${apiUrl}/api/1.0/partner/did/data`,
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': "Bearer " + sessionId},
            data: formData
        });

        return response       

    }

}

export const add_phone_DID_Cli_Routing = (did_id, server_id, caller_id, dest_type, destination, match_explicitly) => {
    return (dispatch, getState) => {
        let sessionId = localStorage.getItem('x-token');
        let config = { params: { did_id, server_id, caller_id, dest_type, destination, match_explicitly }, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
            axios.get(`${apiUrl}/api/1.0/bicom/did/createclirouting`, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                handle_error(dispatch, reject, error);
            })
        });
        return p;
    };
}

export const update_client_transfer_info = (serverParam) => {
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem('x-token');
        
        const formData = new URLSearchParams();
        formData.append('server_id', serverParam.server_id);

        formData.append('db_user', serverParam.db_user);
        formData.append('db_password', serverParam.db_password);
        formData.append('db_base_name', serverParam.db_base_name);
        formData.append('db_view_name', serverParam.db_view_name);
        formData.append('db_host', serverParam.db_host);
        formData.append('db_port', serverParam.db_port);
        
        formData.append('ssh_host', serverParam.ssh_host);
        formData.append('ssh_port', serverParam.ssh_port);
        formData.append('ssh_user', serverParam.ssh_user);
        formData.append('ssh_file', serverParam.ssh_file);
        formData.append('ssh_password', serverParam.ssh_password);

                    
        const response = axios.request({
            url: `${apiUrl}/api/1.0/updateClientTransferInfos`,
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': "Bearer " + sessionId},
            data: formData
        });

        return response       
    }
}

export const create_client_transfer_info = (serverParam) => {
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem('x-token');
        
        const formData = new URLSearchParams();
        formData.append('server_id', serverParam.server_id);

        formData.append('db_user', serverParam.db_user);
        formData.append('db_password', serverParam.db_password);
        formData.append('db_base_name', serverParam.db_base_name);
        formData.append('db_view_name', serverParam.db_view_name);
        formData.append('db_host', serverParam.db_host);
        formData.append('db_port', serverParam.db_port);
        
        formData.append('ssh_host', serverParam.ssh_host);
        formData.append('ssh_port', serverParam.ssh_port);
        formData.append('ssh_user', serverParam.ssh_user);
        formData.append('ssh_file', serverParam.ssh_file);
        formData.append('ssh_password', serverParam.ssh_password);

                    
        const response = axios.request({
            url: `${apiUrl}/api/1.0/createClientTransferInfos`,
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': "Bearer " + sessionId},
            data: formData
        });

        return response       
    }
}

export const get_client_transfer_info = (server_id) => {
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem("x-token");
        let config = {
        params: {server_id},
        headers: { Authorization: "Bearer " + sessionId },
        };
    
        const p = new Promise((resolve, reject) => {
            axios.get(`${apiUrl}/api/1.0/getClientTransferInfos`, config)
            .then((response) => {
                console.log("res ", response)
                resolve(response.data);
            })
            .catch((error) => {
                handle_error(dispatch, reject, error);
            });
        });

        return p;
    }
  };