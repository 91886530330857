import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as ClientActions from "../../../actions/clientTransfer";
import { UI_BUTTON_SIZE } from "../../../constants/index";
//import * as ClientTransferAction from "../../../actions/clientTransfer"

import {
  Modal,
  Segment,
  Icon,
  Divider,
  Button,
  Form,
  Checkbox,
} from "semantic-ui-react";

import { makeCancelable } from "../../../utils";
import { toast } from "react-semantic-toasts";

class ConnectionDBClientModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: true,
      data: [],
      login : "",
      password : "",
      baseName : "",
      tableName : "",
      baseAddress : "",
      basePort : "",
      hostSSH : "",
      portSSH : "",
      usernameSSH : "",
      passwordSSH : "",
      sshPasswordConnection : true,
      newTransferConnection : true,
      sshFile : "",
    };
    if(this.props.oldConnectionData) {
      const res = this.props.oldConnectionData;
        
      this.state.login = res.db_user;
      this.state.password = res.db_password;
      this.state.baseName = res.db_base_name;
      this.state.tableName = res.db_view_name;
      this.state.baseAddress = res.db_host;
      this.state.basePort = res.db_port;
      this.state.hostSSH = res.ssh_host;
      this.state.portSSH = res.ssh_port;
      this.state.usernameSSH = res.ssh_user;
      this.state.passwordSSH = res.ssh_password;
      this.state.sshPasswordConnection = true;
      this.state.sshFile = res.ssh_file;

      if(!res.ssh_file) {  //set the swicth button in password and ssh file input
        this.state.sshPasswordConnection = false;
      }
    }
  };
  componentWillUnmount() {
    if (this.connectDB) this.connectDB.cancel();
    if (this.get_owner) this.get_owner.cancel();
    if (this.create_client_transfer_info) this.create_client_transfer_info.cancel();
    if (this.get_client_transfer_info) this.get_client_transfer_info.cancel();
  }
  handleLogin = (e) => {
    this.setState({ login: e.target.value });
  };
  handlePassword = (e) => {
    this.setState({ password: e.target.value });
  };
  handleBaseName = (e) => {
    this.setState({ baseName: e.target.value });
  };
  handleTableName = (e) => {
    this.setState({ tableName: e.target.value });
  };
  handleBaseAddress = (e) => {
    this.setState({ baseAddress: e.target.value });
  };
  handleBasePort = (e) => {
    this.setState({ basePort: e.target.value });
  };
  handlehostSSH = (e) => {
    this.setState({ hostSSH: e.target.value });
  };
  handlePortSSH = (e) => {
    this.setState({ portSSH: e.target.value });
  };
  handleUserNameSSH = (e) => {
    this.setState({ usernameSSH: e.target.value });
  };
  handlePasswordSSH = (e) => {
    this.setState({ passwordSSH: e.target.value });
  };
  setSSHPasswordConnection = (e) => {
    this.setState({ sshPasswordConnection: e.target.checked });
  };
  handleSSHFile = async (event) => {
    let file = event.target.files[0];
    let temp;
    const reader = new FileReader();
    reader.onload = (e) => {
      temp = e.target.result;
      this.setState({ sshFile: temp });
    };
    reader.readAsText(file);
  };
  handleClose = (event) => {
    event.stopPropagation();
    this.props.onClose(false);
  };
  handleConnection = () => {
    const {
      login,
      password,
      baseName,
      tableName,
      baseAddress,
      basePort,
      hostSSH,
      portSSH,
      usernameSSH,
      passwordSSH,
      sshFile,
      sshPasswordConnection,
      newTransferConnection,
    } = this.state;

    const server_id = this.props.serverId;

    let file;
    if (!sshPasswordConnection)
      file = document.getElementById("sshFile").files[0];

    if (
      login === "" ||
      password === "" ||
      basePort === "" ||
      baseName === "" ||
      baseAddress === "" ||
      tableName === "" ||
      hostSSH === "" ||
      portSSH === "" ||
      usernameSSH === "" ||
      (passwordSSH === "" && !sshFile)
    ) {
      toast({
        type: "warning",
        icon: "warning circle",
        title: "Attention",
        description: "Veillez renseigner les champs manquants !",
        time: 5000,
      });
      return;
    }

    if (!sshPasswordConnection && file === undefined) {
      toast({
        type: "warning",
        icon: "warning circle",
        title: "Attention",
        description: "Veillez choisir un fichier (en .pem) !",
        time: 5000,
      });
      return;
    }

    this.connectDB = makeCancelable(
      this.props.client.get_client_db_data(
        login,
        password,
        baseName,
        tableName,
        baseAddress,
        basePort,
        server_id,

        hostSSH,
        portSSH,
        usernameSSH,
        sshFile,
        sshPasswordConnection,
        passwordSSH
      )
    );

    this.connectDB.promise
      .then((response) => {
        if (newTransferConnection) {
          this.createClientTransferInfo(
            this.props.userId,
            this.props.serverId,
            hostSSH,
            portSSH,
            usernameSSH,
            passwordSSH,
            sshFile,
            login,
            password,
            baseName,
            tableName,
            baseAddress,
            basePort
          );
        } else {
          this.updateClientTransferInfos(
            this.props.userId,
            this.props.serverId,
            hostSSH,
            portSSH,
            usernameSSH,
            passwordSSH,
            sshFile,
            login,
            password,
            baseName,
            tableName,
            baseAddress,
            basePort
          );
        }

        this.props.onDataClientChange(response.data);
        this.props.onConnection(true);
        this.props.onLoading(false);
        this.props.onClose(false);
      })
      .catch((error) => {
        this.props.onConnection(false);
        this.props.onLoading(false);
        toast({
          type: "error",
          icon: "warning circle",
          title: "Erreur",
          description:
            "Les informations que vous avez rentrer ne sont pas bon ou que le server rencontre des difficultés !",
          time: 5000,
        });
        return;
      });
  };
  createClientTransferInfo = (
    user_id,
    server_id,
    ssh_host,
    ssh_port,
    ssh_user,
    ssh_password,
    ssh_file,
    db_user,
    db_password,
    db_base_name,
    db_view_name,
    db_host,
    db_port
  ) => {
    const serverParams = {
      server_id: server_id,

      ssh_host: ssh_host,
      ssh_port: ssh_port,
      ssh_user: ssh_user,
      ssh_password: ssh_password,
      ssh_file: ssh_file,

      db_user: db_user,
      db_password: db_password,
      db_base_name: db_base_name,
      db_view_name: db_view_name,
      db_host: db_host,
      db_port: db_port,
    };

    this.create_client_transfer_info = makeCancelable(
      this.props.client.create_client_transfer_info(serverParams)
    );

    this.create_client_transfer_info.promise
      .then((response) => {
        this.setState({
          newTransferConnection: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  updateClientTransferInfos = (
    user_id,
    server_id,
    ssh_host,
    ssh_port,
    ssh_user,
    ssh_password,
    ssh_file,
    db_user,
    db_password,
    db_base_name,
    db_view_name,
    db_host,
    db_port
  ) => {
    const serverParams = {
      server_id: server_id,

      ssh_host: ssh_host,
      ssh_port: ssh_port,
      ssh_user: ssh_user,
      ssh_password: ssh_password,
      ssh_file: ssh_file,

      db_user: db_user,
      db_password: db_password,
      db_base_name: db_base_name,
      db_view_name: db_view_name,
      db_host: db_host,
      db_port: db_port,
    };

    this.updateClientTransferInfos = makeCancelable(
      this.props.client.update_client_transfer_info(serverParams)
    );

    this.updateClientTransferInfos.promise
      .then((response) => {
        this.setState({
          newTransferConnection: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const {
      login,
      password,
      tableName,
      baseName,
      baseAddress,
      basePort,

      hostSSH,
      portSSH,
      usernameSSH,
      passwordSSH,
      sshPasswordConnection,
    } = this.state;
   
    return (
      <Fragment>
        <Modal
          open={true}
          onClose={this.handleClose}
          centered={false}
          size="small"
        >
          <Modal.Header>
            <Icon circular inverted color="blue" name="database" />
            {"\u00a0\u00a0"}Connection
          </Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <Segment>
                <Form style={{ minHeight: 202 }} encType="multipart/form-data">
                  <Fragment>
                    <fieldset>
                      <legend>Connection SSH</legend>
                      <Form.Group widths="equal">
                        <Form.Input
                          autoFocus
                          fluid
                          label="L'adresse IP ou nom de l'hôte"
                          placeholder="L'adresse IP ou nom de l'hôte"
                          type="text"
                          value={hostSSH}
                          onChange={this.handlehostSSH}
                          required
                        />

                        <Form.Input
                          autoFocus
                          fluid
                          label="Port SSH"
                          placeholder="Port SSH"
                          type="text"
                          value={portSSH}
                          onChange={this.handlePortSSH}
                          required
                        />
                      </Form.Group>

                      <Form.Group widths="equal">
                        <Form.Input
                          autoFocus
                          fluid
                          label="Nom d'utilisateur"
                          placeholder="Nom d'utilisateur"
                          type="text"
                          value={usernameSSH}
                          onChange={this.handleUserNameSSH}
                          required
                        />

                        
                        {sshPasswordConnection ? (
                          <Form.Input
                            autoFocus
                            fluid
                            label="Mot de passe ou fichier (en .pem)"
                            placeholder="Mot de passe"
                            type="password"
                            value={passwordSSH}
                            onChange={this.handlePasswordSSH}
                            required
                          />
                        ) : (
                          
                          <Form.Input 
                            className="inputFile"
                            label="Clé SSH (en .pem) ou mot de passe"
                            placeholder="Clé SSH"
                            type="file"
                            accept=".pem"
                            name="sshFile"
                            id="sshFile"
                            onChange={this.handleSSHFile}
                            required
                          />
                        )}

                        <Checkbox
                          id="passState"
                          style={{ position: "absolute", marginLeft: "85%" }}
                          type="checkbox"
                          checked={sshPasswordConnection}
                          onChange={this.setSSHPasswordConnection}
                          toggle
                        />
                      </Form.Group>
                    </fieldset>

                    <Divider section></Divider>

                    <fieldset>
                      <legend>Connection à la base de données</legend>
                      <Form.Group widths="equal">
                        <Form.Input
                          autoFocus
                          fluid
                          label="Identifiant"
                          placeholder="Identifiant"
                          type="text"
                          value={login}
                          onChange={this.handleLogin}
                          required
                        />
                        <Form.Input
                          fluid
                          label="Mot de passe"
                          type="password"
                          placeholder="Mot de passe"
                          value={password}
                          onChange={this.handlePassword}
                          required
                        />
                      </Form.Group>

                      <Form.Group widths="equal">
                        <Form.Input
                          fluid
                          label="Nom de la base de données"
                          placeholder="Nom de la base de données"
                          value={baseName}
                          onChange={this.handleBaseName}
                          required
                        />
                        <Form.Input
                          fluid
                          label="Nom de la table ou de la vue"
                          placeholder="Nom de la table ou de la vue"
                          value={tableName}
                          onChange={this.handleTableName}
                          required
                        />
                      </Form.Group>

                      <Form.Group widths="equal">
                        <Form.Input
                          fluid
                          label="Adresse de la base de données"
                          placeholder="Adresse de la base de données"
                          value={baseAddress}
                          onChange={this.handleBaseAddress}
                          required
                        />
                        <Form.Input
                          fluid
                          label="Port du server"
                          placeholder="Port du server"
                          value={basePort}
                          onChange={this.handleBasePort}
                          required
                        />
                      </Form.Group>
                    </fieldset>
                  </Fragment>
                </Form>
              </Segment>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button size={UI_BUTTON_SIZE} onClick={this.handleClose}>
              <Icon name="cancel" />
              Annuler
            </Button>
            <Button
              size={UI_BUTTON_SIZE}
              primary
              onClick={this.handleConnection}
            >
              Connection&nbsp;&nbsp;
              <Icon name="check" />
            </Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  client: bindActionCreators(ClientActions, dispatch),
});

export default connect(null, mapDispatchToProps)(ConnectionDBClientModal);
