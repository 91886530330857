import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios';
import * as ClientActions from "../../../actions/clientTransfer";
import { UI_BUTTON_SIZE } from "../../../constants/index";
import * as UserActions from '../../../actions/users';

import { Segment, Grid, Button, Icon, Dimmer, Loader } from "semantic-ui-react";
import ConnectionDBClientModal from "./ConnectionDBClientModal";
import LounaTable from "../../../Components/LounaTable";

import { makeCancelable } from "../../../utils";

class PhoneTransferListView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      open: false,
      trunks: [],
      connection: false,
      dataClient: [], //data from client database
      syncLoading: false,
      user_id: null,

      newTransferConnection: true,
      oldConnectionData: null,
    };

  }
  componentDidMount () {
    this.get_owner = makeCancelable(this.props.users.get_owner(this.props.client_id));
      this.get_owner.promise.then(response => {
        if (this.props.server_id) {

            this.get_client_transfer_info = makeCancelable(    //verify if client identification info are register in a database
              this.props.client.get_client_transfer_info(this.props.server_id)
            );
            this.get_client_transfer_info.promise
              .then((response) => {                          
                this.setStateIcon();
                if(response.length === 0) {
                  this.setState({ newTransferConnection: true });
                }else if(response.length === 1) {
                  this.setState({ newTransferConnection: false, oldConnectionData: response[0] });

                  let sshPasswordConnection = true;

                  if(response[0].ssh_file !== '' || response[0].ssh_file !== null) {
                    sshPasswordConnection = false;
                  }

                  this.setState({ loading: true });

                  this.get_client_db_data_request = makeCancelable(
                    this.props.client.get_client_db_data(   
                    response[0].db_user,
                    response[0].db_password,
                    response[0].db_base_name,
                    response[0].db_view_name,
                    response[0].db_host,
                    response[0].db_port,
                    this.props.server_id,
            
                    response[0].ssh_host,
                    response[0].ssh_port,
                    response[0].ssh_user,
                    response[0].ssh_file,
                    sshPasswordConnection,
                    response[0].ssh_password
                  )
                  );
                  this.get_client_db_data_request.promise
                    .then((response) => {
                      this.setState({ dataClient: response.data, connection: true, loading: false });
                    })
                    .catch((error) => {
                      if (!axios.isCancel(error)) {
                        this.setState({ connection: false, loading: false });
                      }
                  });
                }
              })
            .catch((error) => {
              console.error(error);
            });
        }
      });

  }
  componentWillUnmount () {
    if (this.get_client_transfer_info) this.get_client_transfer_info.cancel();
  }
  handleSyncDID = (e) => {
    //add in Bicom from client database
    e.preventDefault();
    for (let d in this.state.dataClient) {
      this.props.client.add_phone_DID_Cli_Routing(this.state.dataClient[d].did_id, this.state.dataClient[d].server_id, this.state.dataClient[d].phone_number, this.state.dataClient[d].extension_type, this.state.dataClient[d].extension_number, this.state.dataClient[d].match_explicitly);
    }    
  };  
  getExtensionType = (item) => {
    if (item.extension_type === 0) {
      return "Extension";
    } else if (item.extension_type === 2) {
      return "Groupe";
    }
  };
  handleConnection = (connection) => {
    this.setState({ connection });
  };
  handleLoading = (loading) => {
    this.setState({ loading });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleOpen = () => {
    this.setState({ open: true });
  };  
  setStateIcon = (d) => {
    for (let d in this.state.dataClient) {
    console.log(this.state.dataClient[d].did);
    this.props.client
      .get_DID_Cli_Routing(this.props.server_id, this.state.dataClient[d].did)
    
      .then((response) => {
        this.setState({didStatus : response});
      })
      .catch((error) => {
        console.log("error ", error);
      });
    }
  };
  handleDataClientChange = (dataClient) => {
    this.setState({ dataClient });
  };
  handleStateIcon = (d) => {
    if(this.state.didStatus){
      Object.values(this.state.didStatus).forEach((value)=>{
        if (value.callerid !== undefined && value.callerid.replace("%", "") === d.phone_number && value.ext === JSON.stringify(d.extension_number)) {
          value.transfered = true;
      }})
      if(this.state.dataClient[d.id] && this.state.dataClient[d.id].didStatus){
        return <Icon name="check circle" color="green" />;
      }
    }
    else {
      return <Icon name="check circle" color="grey" />;
    }
  };  
  render() {
    const { open, dataClient, connection, syncLoading, user_id, newTransferConnection, oldConnectionData, loading } = this.state;
    const { server_id } = this.props;
    
    let data = dataClient;
    
    return (
      <div style={{ marginTop: 25, marginLeft: 15, marginRight: 15 }}>

        {open && (
          <ConnectionDBClientModal
            parentCallback={this.handleCallback}
            serverId={server_id}
            userId={user_id}
            newTransferConnection={newTransferConnection}
            oldConnectionData={oldConnectionData}
            onConnection={this.handleConnection}
            onDataClientChange={this.handleDataClientChange}
            onLoading={this.handleLoading}
            onClose={this.handleClose}
          ></ConnectionDBClientModal>
        )}        

        <Grid.Column textAlign="left">
          <Button
            size={UI_BUTTON_SIZE}
            color="blue"
            onClick={this.handleOpen}
            disabled={connection}
          >
            <Icon name="database" />
            Se connecter
          </Button>

          <Button
            size={UI_BUTTON_SIZE}
            color="blue"
            onClick={this.handleSyncDID}
            disabled={!connection}
          >
            <Icon name="sync" />
            Synchroniser
          </Button>
        </Grid.Column>    

        {loading ? <Dimmer inverted active><Loader indeterminate>Récupération des données en cours, veuillez patienter</Loader></Dimmer> : null}   

        {syncLoading ? (
          <Dimmer inverted active>
           <Loader indeterminate> Synchronistaion en cours, veuillez patienter</Loader>            
          </Dimmer>
        ) : null}

        <React.Fragment>
          <Segment>
            <LounaTable
              data={data}
              columns={[
                {
                  Header: "Numéro Trunk",
                  id: "number",
                  accessor: "phone_number",
                  sortabe: true,
                },
                {
                  Header: "Extension",
                  accessor: "extension_number",
                  sortable: true,
                },
                {
                  Header: "Type",
                  id: "extension_type",
                  accessor: this.getExtensionType,
                  sortable: false,
                },
                {
                  Header: "Ajouté",
                  id: "state",
                  sortable: false,
                  maxWidth: 100,
                  accessor: this.handleStateIcon,
                },
              ]}
            />
          </Segment>
        </React.Fragment>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  client: bindActionCreators(ClientActions, dispatch),
  users: bindActionCreators(UserActions, dispatch)
});

export default connect(null, mapDispatchToProps)(PhoneTransferListView);
