import axios443 from 'axios';
import {
    axiosApi as axios,
    apiUrl,
    CLIENT_SELECT_PHONE,

    CLIENT_GET_PHONE_LIST,
    CLIENT_GET_PHONE_LIST_SUCCESS,
    CLIENT_GET_PHONE_LIST_FAILED,

    CLIENT_GET_ALARM_LIST,
    CLIENT_GET_ALARM_LIST_SUCCESS,
    CLIENT_GET_ALARM_LIST_FAILED,

    CLIENT_GET_EXTENSIONS,
    CLIENT_GET_EXTENSIONS_SUCCESS,
    CLIENT_GET_EXTENSIONS_FAILED,

} from '../constants/index';
import { handle_error } from './session'


//  /api/1.0/configurationserver
export const get_server_configuration = (server_id) => {
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem('x-token');
        let config = { params: { server_id }, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
            axios.get(`${apiUrl}/api/1.0/configurationserver`, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // if (!error.response)
                //     dispatch(client_getserver_conf_failed({ header: "Erreur!", message: error.message }));
                // todo: gestion du 401
                reject({ header: "Erreur!", message: error.message });
            })
        });
        return p;
    };
};




export const client_select_phone = (phone_id, phone_name) => ({ type: CLIENT_SELECT_PHONE, phone_id, phone_name });
export const client_getphone_list = () => ({ type: CLIENT_GET_PHONE_LIST });
export const client_getphone_list_success = (data) => ({ type: CLIENT_GET_PHONE_LIST_SUCCESS, payload: data });
export const client_getphone_list_failed = (error) => ({ type: CLIENT_GET_PHONE_LIST_FAILED, payload: error });

// /api/1.0/bicom/sync
export const sync_phone_list = (server_id) => {
    return (dispatch, getState) => {
        let sessionId = localStorage.getItem('x-token');
        let config = { params: {server_id}, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
        axios.get(`${apiUrl}/api/1.0/bicom/sync`, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // todo: gestion du 401
                reject({ header: "Erreur!", message: error.message });
            })
        });
        return p;
    };
};

// /api/1.0/bicom/checksync
export const check_sync_phone_list = (server_id) => {
    return (dispatch, getState) => {
        let sessionId = localStorage.getItem('x-token');
        let config = { params: {server_id}, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
        axios.get(`${apiUrl}/api/1.0/bicom/checksync`, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // todo: gestion du 401
                reject({ header: "Erreur!", message: error.message });
            })
        });
        return p;
    };
};

// /api/1.0/phones
export const get_phone_list = (server_id) => {
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem('x-token');
        let config = { params: { server_id }, headers: { 'Authorization': "Bearer " + sessionId } };

        dispatch(client_getphone_list());
        return axios.get(`${apiUrl}/api/1.0/phones`, config)
            .then(response => {
                dispatch(client_getphone_list_success(response.data));
            })
            .catch(error => {
                if (!error.response)
                    dispatch(client_getphone_list_failed({ header: "Erreur!", message: error.message }));
            })
    };
};



export const client_getalarm_list = () => ({ type: CLIENT_GET_ALARM_LIST });
export const client_getalarm_list_success = (data) => ({ type: CLIENT_GET_ALARM_LIST_SUCCESS, payload: data });
export const client_getalarm_list_failed = (error) => ({ type: CLIENT_GET_ALARM_LIST_FAILED, payload: error });


// /api/1.0/alarms
export const get_alarm_list = (server_id) => {
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem('x-token');
        let config = { params: { server_id }, headers: { 'Authorization': "Bearer " + sessionId } };

        dispatch(client_getalarm_list());
        return axios.get(`${apiUrl}/api/1.0/alarms`, config)
            .then(response => {
                dispatch(client_getalarm_list_success(response.data));
            })
            .catch(error => {
                if (!error.response)
                    dispatch(client_getalarm_list_failed({ header: "Erreur!", message: error.message }));
            })
    };
};



export const client_getextensions = () => ({ type: CLIENT_GET_EXTENSIONS });
export const client_getextensions_success = (data) => ({ type: CLIENT_GET_EXTENSIONS_SUCCESS, payload: data });
export const client_getextensions_failed = (error) => ({ type: CLIENT_GET_EXTENSIONS_FAILED, payload: error });

// /api/1.0/extensions
export const get_extensions = (server_id) => {
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem('x-token');
        let config = { params: { server_id }, headers: { 'Authorization': "Bearer " + sessionId } };

        dispatch(client_getextensions());
        return axios.get(`${apiUrl}/api/1.0/extensions`, config)
            .then(response => {
                dispatch(client_getextensions_success(response.data));
            })
            .catch(error => {
                if (!error.response)
                    dispatch(client_getextensions_failed({ header: "Erreur!", message: error.message }));
            })
    };
};





// /api/1.0/keysconfiguration
export const get_phone_meta = (model, version, conf) => {
    return (dispatch, getState) => {
        let path = ['/models', model, version, conf].join('/');

        const p = new Promise((resolve, reject) => {
        return axios443.get(path)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject({ header: "Erreur!", message: error.message });
            })
        });
        return p;
    };
};



// /api/1.0/phones
export const get_phone = (phone_id) => {
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem('x-token');
        let config = { params: { phone_id }, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
            axios.get(`${apiUrl}/api/1.0/phone`, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                handle_error(dispatch, reject, error);
            })
        });
        return p;
    };
};

// /api/1.0/deletePhone
export const delete_phone = (phone_id) => {
    return (dispatch, getState) => {
        let sessionId = localStorage.getItem('x-token');
        let config = {
            params: { phone_id },
            headers: { 'Authorization': "Bearer " + sessionId }
        };

        const p = new Promise((resolve, reject) => {
            axios.get(`${apiUrl}/api/1.0/deletePhone`, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
        });
        
        return p;
    };
};


export const sync_delete_phone = (ext_id, server_id) => {
    return (dispatch, getState) => {
        let sessionId = localStorage.getItem('x-token');
        let config = {
            params: { ext_id, server_id },
            headers: { 'Authorization': "Bearer " + sessionId }
        };
        
        const p = new Promise((resolve, reject) => {
            axios.get(`${apiUrl}/api/1.0/deleteExtension`, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {    
                reject({ header: "Erreur lors de la suppression!", message: error.message });
            })
        });
        
        return p;
    }
}


// /api/1.0/licenses
export const get_licenses = (server_id) => {
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem('x-token');
        let config = { params: { server_id }, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
            axios.get(`${apiUrl}/api/1.0/licenses`, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                handle_error(dispatch, reject, error);
            })
        });
        return p;
    };
};