import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Dimmer, Loader } from 'semantic-ui-react';


import 'react-table/react-table.css';
import 'semantic-ui-css/semantic.min.css';
import './App.css';

import {
  me,
} from './actions/users'


import Locations from  "./pages/Locations"
import AdminView from "./pages/admin/AdminView";
import PartnerView from "./pages/partner/PartnerView";
import WizardTenant from  "./pages/wizard/CreateTenant";
import WizardExtensions from  "./pages/wizard/CreateExtensions";
import ClientView from "./pages/client/ClientView";
import SignInView from "./pages/SignInView"
import RedirectView from './pages/RedirectView'
import Page404 from "./pages/Page404";



class App extends Component {
  constructor(props) {
    super(props)
    this.props.me();
  }

  render_routes = ()=>{

    if (!this.props.user.user.id || this.props.user.user.superToken) {
      return (<SignInView />);
    }
    return (
        <Switch>
          {Locations.AdminView.toRoute({ component: AdminView, invalid: Page404 }, true)}
          {Locations.PartnerView.toRoute({ component: PartnerView, invalid: Page404 }, true)}
          {Locations.WizardTenantView.toRoute({ component: WizardTenant, invalid: Page404 }, true)}
          {Locations.WizardExtensionsView.toRoute({ component: WizardExtensions, invalid: Page404 }, true)}
          {Locations.ClientView.toRoute({ component: ClientView, invalid: Page404 }, false)}
          {Locations.SignInView.toRoute({ component: SignInView, invalid: Page404 }, true)}
          <Route exact path="/404" component={Page404}/>
          <Route exact path="/" component={RedirectView} />
          <Redirect to="/" />
        </Switch>
    )
  }

  render() {
    // loader
    if (!this.props.user.completed)
      return (<Dimmer active><Loader size='medium'>Chargement</Loader></Dimmer>)

    return (
        <Fragment>
      <Router>
            {this.render_routes()}
        </Router>
        <div style={{width: "100%", textAlign: "center", color: "#a2a2a2"}}>Copyright Fonia © {new Date().getFullYear()}</div>
      </Fragment>
    );
  }
}


function mapStateToProps(state, ownProps) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps, {me})(App);
